<template>
  <div>
    <v-toolbar flat>
      <help />
      <v-toolbar-title>Akteure</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn @click="add" class="primary">hinzufügen</v-btn>
    </v-toolbar>
    <v-form ref="akteure_form" v-model="valid">
      <v-container>
        <div>
          <div
            v-for="akteur in akteure"
            :key="akteur.id"
            class="text-fields-row"
          >
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Akteur"
                  v-model="akteur.bezeichnung"
                  :rules="rules.bezeichnung"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="8">
                <v-textarea
                  label="Beschreibung"
                  v-model="akteur.beschreibung"
                  :rules="rules.beschreibung"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-btn
                  @click="remove(akteur.id)"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon dark v-bind="attrs" v-on="on">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> löschen</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              class="mr-1"
              outlined
              @click="saveAkteure"
              v-if="akteure.length > 0 || form_saved == false"
            >
              speichern
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              @click="refreshAkteure"
              outlined
              v-if="akteure.length > 0 || form_saved == false"
            >
              Zurücksetzen
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SystemService from "@/services/old/SystemService.js";
import AkteureService from "@/services/old/AkteureService.js";
import Help from "@/components/old/help/AkteureHelp.vue";

export default {
  name: "Akteure",
  components: { Help },
  data() {
    return {
      valid: true,
      rules: {
        bezeichnung: [v => !!v || "Bezeichnung erforderlich"],
        beschreibung: [v => !!v || "Beschreibung erforderlich"]
      },
      akteure: [],
      // loeschAkteure: [],
      snackbar: false,
      message: "",
      snackbar_timeout: 2000,
      form_saved: true
    };
  },

  methods: {
    refreshAkteure() {
      AkteureService.getAkteure(this.$store.getters.currentSystemId).then(
        response => {
          this.akteure = response.data;
        }
      );
    },
    add() {
      this.akteure.push({
        beschreibung: ""
      });
      this.form_saved = false;
    },

    async remove(index) {
      //let candidate = 
      this.akteure.splice(index, 1);
      //this.loeschAkteure.push(candidate);
      this.form_saved = false;
    },
    async saveAkteure() {
      this.validate();
      if (this.valid) {
        // this.loeschAkteure.forEach(this.delete);
        // this.loeschAkteure = [];

        let resp = await AkteureService.updateAkteure(
          this.akteure,
          this.$store.getters.currentSystemId
        );
        this.akteure = resp.data;
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.form_saved = true;
        } else {
          this.message = resp.data;
        }
          this.refreshCurrentSystem();
        this.snackbar = true;
      }
    },
    async refreshCurrentSystem() {
      let resp1 = await SystemService.getNewestVersionOfSystem(
        this.$store.getters.currentSystemId
      );
      var currSystem = resp1.data;
      this.$store.dispatch("registerSystem", currSystem);

      if (resp1.status == 200) {
        this.message = "Änderungen wurden gespeichert.";
        this.form_saved = true;
      } else {
        this.message = resp1.errorMessage;
      }
    },
    // async delete(akteur) {
    //   let respLoesch = await AkteureService.deleteAkteur(akteur[0].id);

    //   if (respLoesch.status == 200) {
    //     this.message = "Änderungen wurden gespeichert.";
    //     this.form_saved = true;
    //   } else {
    //     this.message = respLoesch.data;
    //   }
    // },
    validate() {
      this.$refs.akteure_form.validate();
    }
  },
  created() {
    this.refreshAkteure();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.form_saved) {
      const answer = window.confirm(
        "Änderungen sind noch nicht gespeichert worden.\nSollen die Änderungen verworfen werden?"
      );
      if (answer) {
        this.form_saved = true;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
