<template>
  <v-footer default color="transparent">
    <v-row justify="end">
      <v-dialog v-model="helpDialog" width="600px" scrollable>
        <template v-slot:activator="{ on, attrsDialog }">
          <v-btn
            class="ma-2"
            text
            icon
            color="secondary"
            v-bind="attrsDialog"
            v-on="on"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5 text-decoration-underline">Akteure</span>
          </v-card-title>
          <v-card-text
            class="text-center"
            style="height: 400px; text-align: start !important;"
          >
            Die Maske <strong>Akteure</strong> dient dazu die externen
            fachlichen Akteure des Systems zu definieren.
            <br />
            <br />
            Ein Akteur bezeichnet ein Element, das mit dem modellierten System
            interagiert. Meistens steht er in Beziehung zu einem Anwendungsfall:
            es ist der Akteur, der einen Anwendungsfall anstößt oder der die
            erwarteten Resultate eines Anwendungsfalls entgegennimmt. Akteure
            sollten nicht mit konkreten handelnden Personen oder Systemen
            verwechselt, sondern eher als eine Art Rolle betrachtet werden.
            Kundenberater ist zum Beispiel als Name für einen Akteur besser
            geeignet als Herr Meier vom Verkauf. (Wikipedia)
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="reveal = true">
              Weitere Hilfe
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      helpDialog: false,
      reveal: false
    };
  }
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
