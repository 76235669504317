import axios from "axios";

export default {
  getAkteure(systemid) {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD + "/akteure/" +
      systemid.substring(1)
    );
  },
  updateAkteure(akteure, systemid) {
    return axios.post(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/akteure/" +
        systemid.substring(1),
        akteure
    );
  },
  deleteAkteur(akteurID) {
    return axios.delete(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/akteure/" +        
        akteurID.substring(1)
    );
  }
};
